import React from 'react';
import LayoutWrapper from '../../components/LayoutWrapper';
import VetDetail from '../../components/VetDetail';

const VetDetailPage = (vet): FC => {
  const title = `${vet?.pageContext?.vet?.firstName} ${vet?.pageContext?.vet?.lastName}`;

  return (
    <LayoutWrapper title={title}>
      <VetDetail />
    </LayoutWrapper>
  );
};

export default VetDetailPage;
