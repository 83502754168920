import React, { useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import defaultAvatar from '../../images/avatar-default-cyan.png';
import { getAllVetApi } from '../../api/getAllVet';
import { VetProfileStyles } from '../VetProfile/styles';
import { useLocation } from '@reach/router';

const VetDetail: FC<VetProfileProps> = () => {
  const [currentVet, setCurrentVet] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const profileSLug = location?.pathname?.split('/')?.[2];

  useEffect(() => {
    setLoading(true);
    getAllVetApi().then((res: { vets: any[] }) => {
      const newVet = res.vets.find((vet) => vet.profileSlug === profileSLug);
      setCurrentVet(newVet);
      setLoading(false);
    });
  }, []);

  return (
    <VetProfileStyles id="vetProfile">
      <div className="personal-content">
        <div className="background-img">
          {currentVet?.avatar ? (
            <img src={currentVet?.avatar} className="vet-img" />
          ) : (
            <div className="vet-img">
              <img src={defaultAvatar} className="vet-icon" />
            </div>
          )}
        </div>
        {loading ? (
          <Spin />
        ) : (
          <>
            <div className="name">
              {currentVet
                ? `${currentVet.firstName} ${currentVet.lastName}`
                : ''}
            </div>
            <div className="title">{currentVet?.qualification}</div>
          </>
        )}
      </div>
      <div className="vet-section">
        <Row gutter={[20, 20]}>
          <Col md={18} sx={24}>
            <div className="main-title">About me</div>
            {loading ? (
              <Spin />
            ) : (
              <div className="desc">{`${
                currentVet?.about ? `${currentVet?.about}` : 'N/A'
              }`}</div>
            )}
          </Col>
          <Col md={6} xs={24}>
            <div>
              <div className="sub-title">Species</div>
              {loading ? (
                <Spin />
              ) : (
                <div className="vet-feature">
                  {currentVet?.supportedSpecies?.join(', ')}
                </div>
              )}
            </div>
            <div>
              <div className="sub-title">Location</div>
              {loading ? (
                <Spin />
              ) : (
                <div className="vet-feature">{`${
                  currentVet?.address?.length > 0
                    ? `${currentVet?.address?.[0]?.suburb}, ${currentVet?.address?.[0]?.state}, ${currentVet?.address?.[0]?.country}`
                    : 'N/A'
                }`}</div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </VetProfileStyles>
  );
};

export default VetDetail;
